<template>
    <form-view 
        v-slot="{ onSuccess, onBack }"
        title="Новая версия"
        create
        url-update="/version/update/:id"
        url-back="/version"
    >
        <version-form @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import VersionForm from '@/components/forms/VersionForm.vue'
export default {
    name: 'VersionCreate',
    components: { FormView, VersionForm }
}
</script>