<template>
    <v-form>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-select
          v-model="$v.form.platform.$model"
          :error-messages="getErrors('form.platform')"
          required
          :items="platforms"
          label="Платформа"
        ></v-select>

        <v-select
          v-if="hasPlatformTypes"
          v-model="$v.form.type.$model"
          :error-messages="getErrors('form.type')"
          required
          :items="platformTypes"
          label="Тип"
        ></v-select>

        <v-switch
          v-model="form.current"
          label="Текущая версия"
        ></v-switch>

        <v-text-field 
            v-model="$v.form.version.$model"
            :error-messages="getErrors('form.version', {
                pattern: 'Неверный формат'
            })"
            label="Версия" 
            persistent-hint
            hint="В формате 1.0.0"
            name="version" 
            type="text" 
            placeholder=" "
            autofocus="autofocus" 
            color="light-green" 
        ></v-text-field>

        <v-text-field 
            v-model="$v.form.required.$model"
            :error-messages="getErrors('form.required', {
                pattern: 'Неверный формат'
            })"
            label="Минимальная версия для обновления" 
            persistent-hint
            hint="В формате 1.0.0"
            name="required" 
            type="text" 
            placeholder=" "
            autofocus="autofocus" 
            color="light-green" 
        ></v-text-field>

        <v-textarea
            v-model="$v.form.description.$model"
            :error-messages="getErrors('form.description')"
            label="Описание" 
            name="comment" 
            color="light-green" 
            clearable
            clear-icon="mdi-close-circle"
            rows="5"
            no-resize
        ></v-textarea>

        <v-file-input
            v-model="$v.form.file.$model"
            ref="fileInput"
            :error-messages="getErrors('form.file')"
            show-size
            label="Файл"
        ></v-file-input>

        <v-progress-linear
            v-model="file_upload.progress"
            v-show="file_upload.show"
            color="light-green"
            buffer-value="0"
            height="25"
        >
            <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
            </template>
        </v-progress-linear>

        <v-divider class="my-4" />

        <action-buttons 
            no-save-and-update
            :waiting-save-and-back="waiting.save.back"
            :waiting-save-and-update="waiting.save.update"
            @back="back"
            @save="upload"
        />
    </v-form>
</template>
<script>
import { mapState } from 'vuex'
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ActionButtons from '@/components/crud/ActionButtons.vue'
import ApiHelper from '@/helpers/ApiHelper'

export default {
    name: 'VersionForm',
    components: { ActionButtons },
    props: {
        model: {
            type: Object
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data() {
        return {
            storeModule: 'version',
            file_upload: {
                show: false,
                progress: 0
            },
            form: {
                platform: '',
                type: '',
                version: '',
                required: '',
                current: true,
                file: null,
                uploaded_filename: '',
                description: ''
            }
        };
    },
    computed: {
        ...mapState('version', ['platforms', 'types']),
        hasPlatformTypes() {
            return _.size(this.form.platform) > 0 ? _.has(this.types, this.form.platform) : false;
        },
        platformTypes() {
            return _.get(this.types, this.form.platform, []);
        }
    },
    validations() {
        return {
            form: {
                platform: { required },
                type: {
                    required: function(val) {
                        return this.hasPlatformTypes ? required(val) : true;
                    }
                },
                version: { 
                    required,
                    pattern: function(val) {
                        const re = /^[0-9]{1,}\.[0-9]{1,}\.[0-9]{1,}$/gi;
                        return re.test(val);
                    }
                },
                required: { 
                    required,
                    pattern: function(val) {
                        const re = /^[0-9]{1,}\.[0-9]{1,}\.[0-9]{1,}$/gi;
                        return re.test(val);
                    } 
                },
                file: {
                    required: function(val) {
                        return this.form.platform === 'ios' || this.form.uploaded_filename.length > 0 ? true : !_.isNil(val);
                    }
                },
                description: { required }
            }
        }
    },
    methods: {
        prepareForm() {
            const formData = new FormData();
            _.forOwn(this.form, (v,k) => {

                let val = v;
                switch(k)
                {
                    case 'current':
                        val = (v === true ? 1 : 0);
                    break;
                }

                formData.append(k, val);
            })

            return formData;
        },
        async upload() {
            if (this.validate()) 
            {
                // Reset progress bar
                this.file_upload.progress = 0;
                this.file_upload.show = true;
                // Get file
                const vFileInput = this.$refs.fileInput.$el.querySelector("input[type=file]");
                const file = vFileInput.files[0];
                // Upload file to server
                const { success, data } = await ApiHelper.upload(
                    '/api/site/file-upload', 
                    {
                        file: file, 
                        upload_dir: 'version'
                    }, 
                    (e) => {
                        this.file_upload.progress = Math.round((e.loaded * 100) / e.total);
                    }
                );

                if(success)
                {
                    this.form.uploaded_filename = _.get(data, 'filename', '');
                    this.form.file = null;
                    this.save(true);
                    return ;
                }
            }
            return false;
        }
    },
    watch: {
        hasPlatformTypes(val) {
            if(!val)
                this.form.type = '';
        }
    }
}
</script>
<style scoped lang="scss">

</style>